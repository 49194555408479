<template>
  <div class="batchBox">
    <el-row class="accountCon">
      <el-col class="leftCon" :span="4">
        <div class="label1">
          <i class="el-icon-collection-tag" /> 会员等级信息
          <img src="@/assets/images/triangle.png" alt />
        </div>
        <div class="label2">
          <i class="el-icon-collection-tag" /> 会员等级规则
          <img src="@/assets/images/triangle.png" alt />
        </div>
      </el-col>
      <el-col class="rightCon" :span="20">
        <el-form
          ref="detail"
          label-position="right"
          label-width="140px"
          :rules="rules"
          :model="detail"
        >
          <el-form-item label="会员等级名称" prop="name">
            <el-input v-model="detail.name" class="w300" maxlength="150" show-word-limit />
          </el-form-item>

          <el-form-item label="大于等于" class="mt100" prop="minimum">
            <el-input v-model="detail.minimum" class="w300" type="span">
              <span slot="append">元</span>
            </el-input>
            <div class="phoneTip" v-if="baseMinimum>0">最小值不得小于{{baseMinimum}}</div>
          </el-form-item>
          <el-form-item label="且小于等于" prop="maximum">
            <el-input v-model="detail.maximum" class="w300" type="span">
              <span slot="append">元</span>
            </el-input>
          </el-form-item>

          <el-form-item label="备注">
            <el-input type="textarea" class="w300" v-model="detail.remarks" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-button type="primary" size="mini" class="mainBtn" @click="submitForm('detail')">完成，提交</el-button>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { addGradeDetail, addGrade, saveGrade, gradeDetail } from "@/api/client";
class Detail {
  name = "";
  minimum = "";
  maximum = "";
  remarks = "";
}
class Rules {
  name = [{ required: true, message: "请填写会员等级名称", trigger: "blur" }];
  minimum = [
    { required: true, message: "请填写最小额度", trigger: "blur" },
    {
      pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
      message: "最多保留两位小数",
      trigger: "change"
    }
  ];
  maximum = [
    { required: true, message: "请填写最大额度", trigger: "blur" },
    {
      pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/,
      message: "最多保留两位小数",
      trigger: "change"
    }
  ];
}
export default {
  name: "AddRank",

  data() {
    return {
      detail: new Detail(),
      rules: new Rules(),
      baseMinimum: 0
    };
  },
  mounted() {
    this.$route.query.id ? this.getDetail() : this.getOptions();
  },
  methods: {
    // 获取默认最小值
    getOptions() {
      addGradeDetail().then(res => {
        this.baseMinimum = res.data.minimum;
      });
    },
    // 优惠券详情
    getDetail() {
      gradeDetail({ id: this.$route.query.id }).then(res => {
        let { name, minimum, maximum, remarks } = res;
        this.detail.name = name;
        this.detail.minimum = minimum;
        this.detail.maximum = maximum;
        this.detail.remarks = remarks;
      });
    },

    // 确定新增商品
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.detail.minimum < this.baseMinimum && this.baseMinimum > 0) {
            this.$message({
              type: "warning",
              message: `最小额度不得小于${this.baseMinimum}`
            });
          } else if (this.detail.minimum > this.detail.maximum) {
            this.$message({
              type: "warning",
              message: `最小额度不得大于最大额度`
            });
          } else {
            this.hint("add", "新建该会员等级");
          }
        } else {
          return false;
        }
      });
    },
    // 提示
    hint(type, text) {
      this.$confirm(`${text}，是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.confirm();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 确定新增/编辑
    confirm() {
      if (this.$route.query.id) {
        saveGrade({ ...this.detail, id: this.$route.query.id }).then(res => {
          this.$message({
            type: "success",
            message: res.msg
          });
          this.$router.push("./memberRank");
        });
      } else {
        addGrade({ ...this.detail }).then(res => {
          this.$message({
            type: "success",
            message: res.msg
          });
          this.$router.push("./memberRank");
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.accountCon {
  background: #fff;
  position: relative;
  .leftCon {
    background: #fcfcfc;
    border-right: 1px solid #eee;
    position: absolute;
    height: 100%;
    > div {
      line-height: 48px;
      color: #fff;
      background: #9ea7b4;
      width: 100%;
      padding-left: 80px;
      font-size: 14px;
      box-sizing: border-box;
      img {
        position: absolute;
        right: -30px;
        width: 40px;
        height: 48px;
      }
    }
    .label1 {
      position: absolute;
      top: 40px;
    }
    .label2 {
      position: absolute;
      top: 210px;
    }
  }
  .rightCon {
    padding: 40px 0 0 500px;
    .btn {
      margin: 20px 0 50px 80px;
    }
    .mainBtn {
      margin: 30px 0 0 200px;
    }
    .phoneTip {
      font-size: 12px;
      color: #999;
    }
  }
}
</style>
